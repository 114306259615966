import { AppstoreOutlined } from "@ant-design/icons";
import React from "react";

export const menuList = [
  {
    name: "caseNames.publicSafety", // "Общественная безопасность",
    img: "safety.svg",
    children: [
      {
        name: "caseNames.dtp", // "ДТП"
        url: "/dtp",
      },
      {
        name: "caseNames.criminalSituation", // "Криминогенная обстановка"
        url: "/crime",
      },
    ],
  },
  {
    name: "caseNames.forecast", // "Прогнозирование",
    img: "forecast.svg",
    children: [
      {
        name: "caseNames.population", // "Численность населения"
        url: "/population",
      },
    ],
  },
  // {
  //   name: "Аналитические индикаторы",
  //   img: "analytical.svg",
  //   children: [
  //     {
  //       name: "ПРТ",
  //       url: "/analytical-indicators",
  //     },
  //   ],
  // },
  {
    name: "caseNames.economy", //"Экономика",
    img: "economy.svg",
    children: [
      // {
      //   name: "caseNames.businessCard", // "Карта бизнеса"
      //   url: "/business-map",
      // },
      {
        name: "caseNames.income", // "Доходы"
        url: "/income",
      },
      {
        name: "caseNames.costs", // "Расходы"
        url: "/outcome",
      },
      {
        name: "caseNames.monitoringState",
        url: "/monitoring-state",
      },
      {
        name: "iQala",
        url: "/iQala",
      },
      {
        name: "caseNames.buisnesMap",
        url: "/buisnesMap",
      },
    ],
  },
  {
    name: "caseNames.healthCare", // "Здравоохранение"
    img: "medicine.svg",
    children: [
      {
        name: "caseNames.morbidityData", // "Данные о заболеваемости (DamuMed)"
        url: "/damu-med",
      },
      // {
      //   name: "Анализ заболеваемости",
      // },
    ],
  },
  {
    name: "caseNames.education", // "Образование"
    img: "education.svg",
    children: [
      {
        name: "caseNames.distributionOfChildrenInKindergartens", // "Распределение детей по дет. садам"
        url: "/ddo",
      },
      // {
      //   name: "caseNames.distributionOfPupilsBySchool", // "Распределение учеников по школам"
      //   url: "/school",
      // },
    ],
  },
  {
    name: "caseNames.analyticalIndicators",
    img: "analyticalIndicators.svg",
    children: [
      {
        name: "caseNames.analyticalIndicators",
        url: "/analytical-indicators",
      },
    ],
  },
  {
    name: "caseNames.social",
    img: "social.svg",
    children: [
      {
        name: "caseNames.social",
        url: "/social",
      },
      {
        name: "caseNames.newAppeals",
        url: "/new-appeals",
      },
      {
        name: "caseNames.appealsMonitoring",
        url: "/appeals-monitoring",
      },
      {
        name: "caseNames.categoryAnalytics",
        url: "/category-analytics",
      },
    ],
  },
  {
    name: "caseNames.ecology",
    img: "ecology.svg",
    children: [
      {
        name: "caseNames.airPollution",
        url: "/air-pollution",
      },
      {
        name: "caseNames.weatherForecast",
        url: "/weather-forecast",
      },
      {
        name: "caseNames.meteorologicalMonitoring",
        url: "/meteorological-monitoring",
      },
      {
        name: "caseNames.hydrologicalMonitoring",
        url: "/hydrological-monitoring",
      },
    ],
  },
  {
    name: "caseNames.videoStreams",
    img: "video.svg",
    children: [
      {
        name: "caseNames.pedestrianFlow",
        url: "/pedestrian-flow",
      },
      {
        name: "caseNames.carFlow",
        url: "/car-flow",
      },
      // {
      //   name: "caseNames.videoStreams",
      //   url: "/video-streams",
      // },
    ],
  },
];
