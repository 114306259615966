/*
  Objects Array = [
    {
      ballon: {
        clusterCaption: "List of objects at the coordinates",
        balloonContentHeader,
        balloonContentBody,
        balloonContentFooter
      }
    }
  ]
  EVENTS 
  onClusterClick - returns array of objects at that cluster
  handleClick - return clicked object
*/

import React from "react";
import { YMaps, Map, ZoomControl, FullscreenControl } from "react-yandex-maps";
import Loading from "../common/LoadingCss";
import PropTypes from "prop-types";

class YandexMapWrap extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      loading: true,
      center: [46.9605, 54.0069],
      objects: [],
    };
    this.clusterer = null;
    this.ymaps = null;
    this.map = null;
  }

  onApiAvaliable = (ymaps) => {
    this.ymaps = ymaps;
  };

  setMapControlInstanceRef = (ref) => {
    const { polygon } = this.props;
    this.map = ref;
    setTimeout(() => {
      if (this.ymaps) {
        this.createCluster();
        if (polygon.length !== 0);
      }
      this.setState({ loading: false });
    }, 2000);
  };

  onObjectClick = (obj) => {
    const { idDataKey } = this.props;
    if (idDataKey) {
      this.props.handleClick(obj[idDataKey]);
    } else {
      this.props.handleClick(obj);
    }
    this.changeMapCenter(obj);
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.map) {
      this.map.destroy();
    }
  }

  createPolygon = (objects) => {
    if (!objects[0]) return [];
    let polygons = [];
    objects.forEach((e) => {
      let polygon = new this.ymaps.Polygon(
        e.data,
        {
          hintContent: e.balloonContent,
        },
        {
          fillColor: "#ffff00",
          strokeWidth: 1,
          fillOpacity: 0.3,
        }
      );
      polygons.push(polygon);
    });
    return polygons;
  };

  createGeoObjects = (objects) => {
    const { xDataKey, yDataKey } = this.props;
    let geoObjects = [];
    if (!objects[0]) return [];
    objects.forEach((el) => {
      let coords = [el[yDataKey], el[xDataKey]];
      if (coords) {
        let obj = this.props.placemark
          ? new this.ymaps.Placemark(coords, el.ballon, {
              iconColor: el.color ? el.color : "#3caa3c",
              preset: "islands#circleIcon",
            })
          : new this.ymaps.GeoObject(
              {
                // Описание геометрии.
                geometry: {
                  type: "Point",
                  coordinates: coords,
                },
                // Свойства.
                // hasBalloon: ...el.ballon,
                properties: {
                  // Контент метки.
                  // iconContent: `Въехавших авто: ${el["Blockposts.in"]} Выехавших авто: ${el["Blockposts.out"]}`,
                  iconContent: `${el["Blockposts.shortName"]}:  ${el["Blockposts.in"]} / ${el["Blockposts.out"]}`,
                  balloonContentHeader: el.ballon
                    ? el.ballon.balloonContentHeader
                    : "",
                  balloonContent: el.ballon ? el.ballon.balloonContentBody : "",
                },
              },
              {
                // Опции.
                // Иконка метки будет растягиваться под размер ее содержимого.
                preset:
                  el["Blockposts.type"] === "Блокпост"
                    ? "islands#redStretchyIcon"
                    : "islands#blueStretchyIcon",
              }
            );

        obj.dataObject = el;
        obj.events.add("click", () => {
          this.onObjectClick(el);
        });
        geoObjects.push(obj);
      }
    });
    return geoObjects;
  };

  onClustererClick = (ev) => {
    if (!ev.get("target").dataObject) {
      const objects = ev
        .get("target")
        .getGeoObjects()
        .map((el) => el.dataObject);
      if (this.props.onClusterClick) {
        this.props.onClusterClick(objects);
      }
    }
  };

  createCluster = () => {
    const clusterer = new this.ymaps.Clusterer({
      hasBalloon: false,
      clusterDisableClickZoom: true,
      clusterIconLayout: "default#pieChart",
      clusterIconPieChartRadius: 25,
      clusterIconPieChartCoreRadius: 10,
      clusterIconPieChartStrokeWidth: 3,
      groupByCoordinates: false,
    });
    clusterer.events.add("click", (e) => {
      this.onClustererClick(e);
    });
    let geoObjects = this.createGeoObjects(this.state.objects);
    let polygons = this.createPolygon(this.props.polygon);
    if (polygons[0] && this.map)
      polygons.forEach((e) => this.map.geoObjects.add(e));
    if (this.props.clusterer) {
      if (geoObjects[0]) clusterer.add(geoObjects);
      this.clusterer = clusterer;
    }
    if (this.map)
      this.props.clusterer
        ? this.map.geoObjects.add(clusterer)
        : geoObjects.forEach((e) => this.map.geoObjects.add(e));
  };

  componentDidUpdate() {
    const { objects, xDataKey, yDataKey } = this.props;
    const filteredObjects = objects.filter(
      (el) => !!el[xDataKey] && !!el[yDataKey]
    );
    if (
      this.state.objects.length !== filteredObjects.length &&
      this._isMounted
    ) {
      this.setState({ objects: filteredObjects }, () => {
        if (this.clusterer && this.props.clusterer) this.refreshClusterer();
        if (this.props.clusterer === false && this.state.loading === false)
          this.refreshGeoObjects();
        if (!this.props.showDefaultCity)
          if (filteredObjects[0]) this.changeMapCenter(filteredObjects[0]);
      });
    }
  }

  changeMapCenter = (el) => {
    const { xDataKey, yDataKey } = this.props;
    let coords = [el[yDataKey], el[xDataKey]];
    this.setState({ center: coords });
  };

  refreshGeoObjects = () => {
    this.map.geoObjects.removeAll();
    let geoObjects = this.createGeoObjects(this.state.objects);
    let polygons = this.createPolygon(this.props.polygon);
    geoObjects.forEach((e) => this.map.geoObjects.add(e));
    polygons.forEach((e) => this.map.geoObjects.add(e));
  };

  refreshClusterer = () => {
    this.clusterer.removeAll(); // Delete all geoObjects
    let geoObjects = this.createGeoObjects(this.state.objects);
    if (geoObjects[0]) {
      this.clusterer.add(geoObjects); // Add new GeoObjeccts
    }
  };

  render() {
    let { height, zoom = 13 } = this.props;
    return (
      <React.Fragment>
        {this.state.loading && <Loading />}
        <YMaps onApiAvaliable={this.onApiAvaliable}>
          <Map
            state={{
              center: this.state.center,
              zoom: zoom,
              controls: [],
            }}
            width="100%"
            height={height ? height : "55vh"}
            instanceRef={this.setMapControlInstanceRef}
          >
            <ZoomControl
              options={{
                size: "small",
                zoomDuration: 1000,
              }}
            />
            <FullscreenControl />
          </Map>
        </YMaps>
      </React.Fragment>
    );
  }
}
export default YandexMapWrap;

YandexMapWrap.defaultProps = {
  idDataKey: "",
  xDataKey: "x",
  yDataKey: "y",
  zoom: 10,
  height: "55vh",
  clusterer: true,
  placemark: true,
  polygon: [],
};

YandexMapWrap.propTypes = {
  dictionary: PropTypes.object,
  data: PropTypes.array.isRequired,
  idDataKey: PropTypes.string,
  xDataKey: PropTypes.string,
  yDataKey: PropTypes.string,
  handleClick: PropTypes.func,
  onClusterClick: PropTypes.func,
  zoom: PropTypes.number,
  height: PropTypes.string,
  type: PropTypes.string,
  clusterer: PropTypes.bool,
  placemark: PropTypes.bool,
  polygon: PropTypes.array,
};
