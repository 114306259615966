import React from "react";

export const MeteorologicalMonitoring = () => {
  return (
    <iframe
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        border: "1px solid gray",
      }}
      title="Monitoring state"
      src="https://sc.smartkulsary.kz/meteorological/"
    ></iframe>
  );
};
