import React, { Component } from "react";
import { QueryRenderer } from "@cubejs-client/react";
import { Modal } from "antd";
import { cloneDeep, groupBy, reduce, uniq } from "lodash";
import moment from "moment";

import OtherDetails from "./OtherDetails";

import ExportExcel from "../../../components/ExportExcelNew";
import { ChartCard } from "../../../components/ChartCard/ChartCard";
import { DatePickerR } from "../../../components/DatePickerR";
import { FilterItem } from "../../../components/FilterItemNew/FilterItem";
import { FilterWrapper } from "../../../components/FilterWrapper/FilterWrapper";
import { MainWrapper } from "../../../components/MainWrapper/MainWrapper";
import PieTag from "../../../components/PieTag/PieTag";
import { RadioGroup } from "../../../components/RadioGroup";
import { RowWrapper } from "../../../components/RowWrapper/RowWrapper";
import { SelectList } from "../../../components/SelectList/SelectList";
import PieChart from "../../../components/visualizations/PieChart";
import VerticalBarChart from "../../../components/visualizations/VerticalBarChartNew";
import {
  dateGroupsNew,
  DAY_IN_MONTH,
  DAY_IN_WEEK,
  HOUR_IN_DAY,
  WEEK_DAYS,
} from "../../../constants";
import cubejs from "../../../cube";
import {
  generateBarData,
  generatePieData,
} from "../../../utils/visualizations";

const cubejsApi = cubejs({ appId: 1 });
class CategoryAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersData: [
        {
          key: "Appeals.category",
          title: "Категория",
          options: [],
          placeholder: "Выберите категорию",
        },
        {
          key: "Appeals.subCategory",
          title: "Подкатегория",
          options: [],
          placeholder: "Выберите подкатегорию",
        },
      ],
      range: [
        moment().startOf("month").utc(6).startOf("day"),
        moment().utc(6).endOf("day"),
      ],
      pieLegends: [],
      averageAppeals: 0,
      granularity: "day",
      radioValue: "Appeals.category",
      categories: [],
      loading: false,
      subCategories: [],
      selectedRegions: [],
      selectedDistricts: [],
      regions: [],
      districts: [],
      pieTitle: "",
      tempGroup: null,
      tempGroupLoading: false,
      disabledRegion: false,
      disabledDistrict: false,
      visible: false,
      selectedPie: {
        type: null,
        val: null,
      },
    };
  }

  async componentDidMount() {
    this.loadDictionaries();
    this.loadFilters();
    this.checkForRegion();
  }

  async loadDictionaries() {
    const regions = await cubejsApi.load({
      dimensions: [
        "AppealsRegion.nameRu",
        "AppealsRegion.parentId",
        "AppealsRegion.id",
      ],
      filters: [
        {
          member: "AppealsRegion.parentId",
          operator: "notSet",
        },
      ],
    });

    const districts = await cubejsApi.load({
      dimensions: [
        "AppealsRegion.nameRu",
        "AppealsRegion.parentId",
        "AppealsRegion.id",
      ],
      filters: [
        {
          member: "AppealsRegion.parentId",
          operator: "set",
        },
      ],
    });
    this.setState({
      regions: regions.rawData(),
      districts: districts.rawData(),
    });
  }

  async loadFilters() {
    let { filtersData, range } = this.state;
    let filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: range,
      },
    ];

    const reqs = filtersData.map((f) =>
      cubejsApi.load({
        dimensions: [f.key, "Appeals.region", "Appeals.district"],
        filters,
      })
    );
    const responses = await Promise.all(reqs);

    responses.forEach((res, i) => {
      filtersData[i].options = res.rawData();
    });
    this.setState({ filtersData });
  }

  hideDetails = () => {
    this.setState({ visible: false });
  };

  checkForRegion = () => {
    let role = JSON.parse(localStorage.getItem("user_params"));
    if (role?.roles?.[0]?.region?.length) {
      const selectedRegions = role.roles[0].region
        .filter((region) => !region?.parentId)
        .map((region) => region.regionName);

      /*
       * Эта часть кода была создана специально
       * для учетных записей user-krg-bkh и
       * user-krg-kzb. Данный кейс является
       * уникальным, поэтому логика
       * прописывалась исключительно под них.
       */

      let krgDistrict = [];

      if (role.userId === 44) {
        krgDistrict = [role.roles[0].region[0].child[0].regionName];
      } else if (role.userId === 45) {
        krgDistrict = [role.roles[0].region[0].child[1].regionName];
      }

      this.setState({
        selectedRegions,
        selectedDistricts: krgDistrict,
        disabledRegion: true,
        disabledDistrict: krgDistrict.length || false,
      });
    }
  };

  applyFilters = (filter, values) => {
    const { regions, districts, selectedRegions } = this.state;
    if (filter === "selectedRegions" && !values?.length) {
      this.setState({
        selectedDistricts: [],
      });
      this.resetSelectedPie();
    }
    if (filter === "selectedDistricts" && selectedRegions.length === 0) {
      const districtObject = districts.find(
        (district) => district["AppealsRegion.nameRu"] === values[0]
      );
      const regionObject = regions.find(
        (region) =>
          region["AppealsRegion.id"] ===
          districtObject["AppealsRegion.parentId"]
      );
      this.setState({
        selectedRegions: [regionObject["AppealsRegion.nameRu"]],
      });
    }
    this.setState({
      [filter]: values,
      categories: [],
      subCategories: [],
    });
  };

  changeRange = (value) => {
    if (value === DAY_IN_MONTH || value === DAY_IN_WEEK)
      this.setState({
        granularity: "day",
        tempGroup: value,
        tempGroupLoading: true,
      });
    else if (value === HOUR_IN_DAY)
      this.setState({
        granularity: "hour",
        tempGroup: value,
        tempGroupLoading: true,
      });
    else
      this.setState({
        granularity: value,
        tempGroup: null,
        tempGroupLoading: true,
      });

    setTimeout(() => {
      this.setState({
        tempGroupLoading: false,
      });
    }, 1000);
  };

  radioChange = (evt) => {
    this.setState({
      radioValue: evt.target.value,
    });
  };

  changeFilters = (val, key) => {
    if (key === "Appeals.category") {
      this.setState({ categories: val });
    } else {
      this.setState({ subCategories: val });
    }
  };

  calcPercent = (data) => {
    if (data) {
      let summary = 0;
      const noOtherData = data.filter((it) => !it.sliceGrouperOther);
      noOtherData.forEach((d) => {
        summary = summary + parseFloat(d["Appeals.appealsNum"]);
      });
      return noOtherData.map((i) => ({
        ...i,
        percent: ((i["Appeals.appealsNum"] * 100) / summary).toFixed(2),
      }));
    }
  };

  getGroupedTemp = (barData = {}) => {
    let { tempGroup } = this.state;
    let newData = [],
      { data = [], ...others } = barData;
    if (tempGroup) {
      const groupedDays = groupBy(data, (el) => {
        if (tempGroup === DAY_IN_MONTH)
          return moment(el["Appeals.submissionDate"], "YYYY/MM/DD").format("D");
        else if (tempGroup === DAY_IN_WEEK)
          return moment(
            el["Appeals.submissionDate"],
            "YYYY/MM/DD"
          ).isoWeekday();
        else if (tempGroup === HOUR_IN_DAY)
          return parseInt(moment(el["Appeals.submissionDate"]).format("HH"));
      });
      Object.keys(groupedDays).forEach((key) => {
        newData.push({
          "Appeals.submissionDate":
            tempGroup === DAY_IN_WEEK
              ? WEEK_DAYS[key - 1]
              : tempGroup === HOUR_IN_DAY
              ? key + ":00"
              : key,
          "Appeals.appealsNum": reduce(
            groupedDays[key],
            function (sum, n) {
              return sum + parseFloat(n["Appeals.appealsNum"]);
            },
            0
          ),
        });
      });
      return { data: newData, ...others };
    } else return { data, ...others };
  };

  filteredDistricts = () => {
    const { districts, regions, selectedRegions } = this.state;
    return districts
      .filter((district) => {
        if (!selectedRegions.length) {
          return true;
        }
        const ids = [];
        selectedRegions.forEach((region) => {
          const regionObj = regions.find(
            (it) => region === it["AppealsRegion.nameRu"]
          );
          ids.push(regionObj?.["AppealsRegion.id"]);
        });
        return ids.includes(district["AppealsRegion.parentId"]);
      })
      .map((it) => it["AppealsRegion.nameRu"]);
  };

  filteredCategories = (options, key) => {
    const {
      selectedDistricts,
      selectedRegions,
      districts,
      regions,
    } = this.state;
    const newOptions = options
      .filter((item) => {
        if (!selectedRegions?.length) {
          return true;
        }
        if (!selectedDistricts?.length) {
          const names = [];
          selectedRegions.forEach((region) => {
            const regionObj = regions.find(
              (it) => region === it["AppealsRegion.nameRu"]
            );
            names.push(regionObj?.["AppealsRegion.nameRu"]);
          });
          return names.includes(item["Appeals.region"]);
        }
        const districtNames = [];
        const regionNames = [];
        selectedDistricts.forEach((district) => {
          const districtObj = districts.find(
            (it) => district === it["AppealsRegion.nameRu"]
          );
          districtNames.push(districtObj?.["AppealsRegion.nameRu"]);
        });
        selectedRegions.forEach((region) => {
          const regionObj = regions.find(
            (it) => region === it["AppealsRegion.nameRu"]
          );
          regionNames.push(regionObj?.["AppealsRegion.nameRu"]);
        });

        return (
          districtNames.includes(item["Appeals.district"]) &&
          regionNames.includes(item["Appeals.region"])
        );
      })
      .map((item) => item[key]);
    return uniq(newOptions);
  };

  resetSelectedPie = () => {
    this.setState({
      selectedPie: { type: null, val: null },
      pieLegends: [],
    });
  };

  handlePieClick = (val, type) => {
    const { range, selectedRegions, selectedDistricts } = this.state;
    let filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: range,
      },
    ];
    if (selectedRegions?.length) {
      filters.push({
        member: "Appeals.region",
        operator: "equals",
        values: selectedRegions,
      });
    }
    if (selectedDistricts?.length) {
      filters.push({
        member: "Appeals.district",
        operator: "equals",
        values: selectedDistricts,
      });
    }
    if (val === "Другие") {
      let query = { measures: ["Appeals.appealsNum"], filters };
      query.dimensions = [type];
      this.setState({ selectedPie: { type, val }, visible: true });
      this.setPieData(query, type, "other");
    } else {
      const dim =
        type === "Appeals.category"
          ? "Appeals.subCategory"
          : "Appeals.category";
      let query = {
        dimensions: [dim],
        measures: ["Appeals.appealsNum"],
        filters: [
          ...filters,
          {
            dimension: type,
            operator: "contains",
            values: [val],
          },
        ],
      };
      this.setState({ selectedPie: { type, val } });
      this.setPieData(query, dim);
    }
  };

  setPieData = (query, type, other) => {
    cubejsApi.load(query).then((r) => {
      let data = r
        .rawData()
        .map((e) => ({ title: e[type], value: +e["Appeals.appealsNum"] }));
      const total = data.reduce((a, b) => a + b.value, 0);
      data = data.map((e, i) => ({
        ...e,
        percent: ((e.value * 100) / total).toFixed(2),
      }));
      if (other) {
        this.setState({
          pieLegends: data.filter((e) => +e.percent < 1),
        });
      } else {
        this.setState({ pieLegends: data });
      }
    });
  };

  hideDetails = () => {
    this.setState({
      visible: false,
      selectedPie: { type: null, val: null },
      pieLegends: [],
    });
  };

  render() {
    const {
      range,
      granularity,
      filtersData,
      radioValue,
      categories,
      subCategories,
      tempGroup,
      tempGroupLoading,
      selectedRegions,
      selectedDistricts,
      regions,
      districts,
      disabledRegion,
      disabledDistrict,
      loading,
      pieLegends,
      visible,
      selectedPie,
    } = this.state;

    let filters = [
      {
        member: "Appeals.submissionDate",
        operator: "inDateRange",
        values: range,
      },
      {
        member: "Appeals.region",
        operator: "equals",
        values: selectedRegions.length
          ? selectedRegions
          : regions.map((it) => it["AppealsRegion.nameRu"]),
      },
      {
        member: "Appeals.district",
        operator: "equals",
        values: selectedDistricts.length
          ? selectedDistricts
          : districts.map((it) => it["AppealsRegion.nameRu"]),
      },
    ];

    return (
      <MainWrapper>
        <FilterWrapper>
          <FilterItem>
            <SelectList
              label="Населенный пункт:"
              mode="multiple"
              value={selectedDistricts}
              onChange={(value) =>
                this.applyFilters("selectedDistricts", value)
              }
              defaultValue={[]}
              disabled={disabledDistrict}
              size="small"
              allowClear
              list={this.filteredDistricts()}
            />
          </FilterItem>

          <FilterItem>
            <DatePickerR
              label={"Период:"}
              onChange={(range) => {
                this.setState({ range }, this.loadFilters);
              }}
              showTime={{
                secondStep: 60,
                minuteStep: 15,
              }}
              allowClear={false}
              value={range}
              size="small"
              separator="—"
            />
          </FilterItem>
        </FilterWrapper>
        <RowWrapper>
          <ChartCard title="Категории">
            {selectedPie?.type === "Appeals.category" && (
              <PieTag
                onClose={this.resetSelectedPie}
                title={"Выбранная категория: " + selectedPie.val}
              />
            )}
            <QueryRenderer
              query={{
                measures: ["Appeals.appealsNum"],
                dimensions: ["Appeals.category"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return null;
                const { data, nameKey, dataKey } = generatePieData(resultSet);
                return (
                  <>
                    <PieChart
                      loading={loading}
                      hideSmallValues
                      id="piechartid1"
                      legendCopyable
                      height="500px"
                      showTotal
                      disableMovement
                      handleClick={(evt) =>
                        this.handlePieClick(evt, "Appeals.category")
                      }
                      data={
                        selectedPie.type === "Appeals.subCategory"
                          ? pieLegends
                          : data
                      }
                      nameKey={
                        selectedPie.type === "Appeals.subCategory"
                          ? "title"
                          : nameKey
                      }
                      dataKey={
                        selectedPie.type === "Appeals.subCategory"
                          ? "value"
                          : dataKey
                      }
                    />
                    <ExportExcel
                      style={{ position: "absolute", top: 10, right: 10 }}
                      filename="Категории"
                      data={this.calcPercent(generatePieData(resultSet)?.data)}
                      fields={[
                        { title: "Категория", dataIndex: "Appeals.category" },
                        {
                          title: "Количество",
                          dataIndex: "Appeals.appealsNum",
                        },
                        {
                          title: "Процент",
                          dataIndex: "percent",
                        },
                      ]}
                    />
                  </>
                );
              }}
            />
          </ChartCard>
          <ChartCard title="Подкатегории">
            {selectedPie?.type === "Appeals.subCategory" && (
              <PieTag
                onClose={this.resetSelectedPie}
                title={"Выбранная подкатегория: " + selectedPie.val}
              />
            )}
            <QueryRenderer
              query={{
                measures: ["Appeals.appealsNum"],
                dimensions: ["Appeals.subCategory"],
                filters,
              }}
              cubejsApi={cubejsApi}
              render={({ resultSet }) => {
                if (!resultSet) return null;
                const { data, nameKey, dataKey } = generatePieData(resultSet);

                return (
                  <>
                    <PieChart
                      loading={loading}
                      id="piechartid2"
                      showTotal
                      hideSmallValues
                      legendCopyable
                      disableMovement
                      height="500px"
                      handleClick={(evt) =>
                        this.handlePieClick(evt, "Appeals.subCategory")
                      }
                      data={
                        selectedPie.type === "Appeals.category"
                          ? pieLegends
                          : data
                      }
                      nameKey={
                        selectedPie.type === "Appeals.category"
                          ? "title"
                          : nameKey
                      }
                      dataKey={
                        selectedPie.type === "Appeals.category"
                          ? "value"
                          : dataKey
                      }
                    />
                    <ExportExcel
                      filename="Подкатегории"
                      data={this.calcPercent(generatePieData(resultSet)?.data)}
                      style={{ position: "absolute", top: 10, right: 10 }}
                      fields={[
                        {
                          title: "Подкатегория",
                          dataIndex: "Appeals.subCategory",
                        },
                        {
                          title: "Количество",
                          dataIndex: "Appeals.appealsNum",
                        },
                        {
                          title: "Процент",
                          dataIndex: "percent",
                        },
                      ]}
                    />
                  </>
                );
              }}
            />
          </ChartCard>
        </RowWrapper>

        <h2 style={{ marginTop: "30px", textAlign: "left" }}>
          Количество обращений в разрезе периода
        </h2>
        <RadioGroup
          onChange={this.radioChange}
          value={radioValue}
          list={[
            {
              key: "Appeals.category",
              value: "Категории",
            },
            {
              key: "Appeals.subCategory",
              value: "Подкатегории",
            },
          ]}
        />
        <div style={{ minHeight: "50vh" }}>
          <FilterWrapper>
            <FilterItem>
              <SelectList
                label={"Шаблон:"}
                onChange={(value) => this.changeRange(value)}
                defaultValue={granularity}
                showKey="label"
                valueKey="granularity"
                list={dateGroupsNew}
              />
            </FilterItem>
            {filtersData.map((el) => {
              return (
                el.key === radioValue && (
                  <FilterItem>
                    <SelectList
                      label={el.placeholder + ":"}
                      onChange={(val) => this.changeFilters(val, el.key)}
                      mode="multiple"
                      value={
                        el.key === "Appeals.category"
                          ? categories
                          : subCategories
                      }
                      size="small"
                      list={this.filteredCategories(el.options, el.key).concat(
                        "Все"
                      )}
                    />
                  </FilterItem>
                )
              );
            })}
          </FilterWrapper>

          <RowWrapper>
            {radioValue === "Appeals.category" &&
              categories.map((cat) => (
                <ChartCard title={cat} key={cat}>
                  <QueryRenderer
                    query={{
                      measures: ["Appeals.appealsNum"],
                      timeDimensions: [
                        {
                          dimension: "Appeals.submissionDate",
                          granularity: granularity,
                        },
                      ],
                      filters: [
                        ...filters,
                        {
                          dimension: "Appeals.category",
                          operator: cat !== "Все" ? "equals" : "notEquals",
                          values: [cat],
                        },
                      ],
                      renewQuery: true,
                    }}
                    cubejsApi={cubejsApi}
                    render={({ resultSet, loadingState }) => {
                      return (
                        <VerticalBarChart
                          loading={loadingState?.isLoading || tempGroupLoading}
                          id={`VerticalBarChart${cat}`}
                          showLegend={false}
                          dateAxis={!tempGroup}
                          height="450px"
                          {...this.getGroupedTemp(
                            cloneDeep(generateBarData(resultSet))
                          )}
                          values={[
                            {
                              title: "Количество обращений",
                              key: "Appeals.appealsNum",
                            },
                          ]}
                          rotate
                        />
                      );
                    }}
                  />
                </ChartCard>
              ))}
            {radioValue === "Appeals.subCategory" &&
              subCategories.map((cat) => (
                <ChartCard title={cat} key={cat}>
                  <QueryRenderer
                    query={{
                      measures: ["Appeals.appealsNum"],
                      timeDimensions: [
                        {
                          dimension: "Appeals.submissionDate",
                          granularity: granularity,
                        },
                      ],
                      filters: [
                        ...filters,
                        {
                          dimension: "Appeals.subCategory",
                          operator: cat !== "Все" ? "equals" : "notEquals",
                          values: [cat],
                        },
                      ],
                      renewQuery: true,
                    }}
                    cubejsApi={cubejsApi}
                    render={({ resultSet, loadingState }) => {
                      return (
                        <VerticalBarChart
                          loading={loadingState?.isLoading || tempGroupLoading}
                          id={`VerticalBarChart${cat}`}
                          dateAxis={!tempGroup}
                          height="450px"
                          {...this.getGroupedTemp(
                            cloneDeep(generateBarData(resultSet))
                          )}
                          values={[
                            {
                              title: "Количество обращений",
                              key: "Appeals.appealsNum",
                            },
                          ]}
                          rotate
                        />
                      );
                    }}
                  />
                </ChartCard>
              ))}
          </RowWrapper>
        </div>
        <Modal
          title={
            selectedPie.type === "Appeals.category"
              ? "Другие категории обращний"
              : "Другие подкатегории обращений"
          }
          visible={visible}
          onCancel={this.hideDetails}
          width={800}
          footer={false}
          centered
          destroyOnClose
        >
          <OtherDetails data={pieLegends} type={selectedPie.type} />
        </Modal>
      </MainWrapper>
    );
  }
}

export default CategoryAnalytics;
