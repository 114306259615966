import React from "react";

export const WeatherForecast = () => {
  return (
    <iframe
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        border: "1px solid gray",
      }}
      title="Monitoring state"
      src="https://m.rp5.kz/%D0%9F%D0%BE%D0%B3%D0%BE%D0%B4%D0%B0_%D0%B2_%D0%9A%D1%83%D0%BB%D1%8C%D1%81%D0%B0%D1%80%D1%8B"
    ></iframe>
  );
};
