import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import Login from "./pages/Login/Login";
import { connect } from "react-redux";
import CrimeData from "./pages/Operational/CrimeData";
import Dtp from "./pages/Operational/Dtp";
import PopulationForecast from "./pages/Forecast/Population";
import Damumed from "./pages/DamuMed";
import MainLayout from "./components/layout/MainLayout";
import Kindergarden from "./pages/PreSchoolEducation/KindergardenQueue";
import AnalyticalIndicators from "./pages/AnalyticalIndicators";
import Income from "pages/Economy/Income";
import Outcome from "pages/Economy/Outcome";
import MainPage from "pages/MainPage/MainPage";
import AppealsCitizen from "pages/Social/AppealsCitizen";
import NewAppeals from "pages/Social/NewAppeals";
import AppealsMonitoring from "pages/Social/AppealsMonitoring";
import CategoryAnalytics from "pages/Social/CategoryAnalytics";
import Statistics from "pages/Statistics";
import Ecology from "pages/Ecology";
import VideoStreamContainer from "components/VideoStream";
import Error from "pages/Error/Error";
import PrivateRoute from "./utils/PrivateRoute";

import { redirect } from "redux/redirect";
import { withTranslation } from "react-i18next";
import bcrypt from "bcryptjs";
import { MonitoringState } from "pages/MonitoringState";
import { IQala } from "pages/IQala";
import { WeatherForecast } from "pages/WeatherForecast";
import { MeteorologicalMonitoring } from "pages/MeteorologicalMonitoring";
import { BuisnesMap } from "pages/BuisnesMap";
import { HydrologicalMonitoring } from "./pages/HydrologicalMonitoring";
// Указываем пути именно так, без начального `./`, иначе webpack не найдёт файлы.

class App extends Component {
  state = {
    currentUser: {},
    casesRoles: [],
  };
  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.redirectTo) {
      this.props.history.push(nextProps.redirectTo);
      this.props.onRedirect();
    }
  }

  checkRoute() {
    let check = false;
    let checkModules = localStorage.getItem("modules") !== null ? true : false;
    if (checkModules)
      JSON.parse(localStorage.getItem("modules")).forEach((module) => {
        if (bcrypt.compareSync(this.props.location.pathname, module))
          check = true;
      });
    return checkModules ? check : false;
  }

  render() {
    let { t } = this.props;
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route
          exact
          path="/error"
          component={(item) => <MainLayout component={Error} />}
        />
        <PrivateRoute
          exact
          path="/dtp"
          component={(item) => (
            <MainLayout
              title={t("caseNames.roadTrafficAccidents")}
              component={Dtp}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <Route
          exact
          path="/population"
          component={(item) => (
            <MainLayout
              title={t("caseNames.population")}
              component={PopulationForecast}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/ddo"
          component={(item) => (
            <MainLayout
              title={t("caseNames.distributionOfChildrenInKindergartens")}
              component={Kindergarden}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <Route
          exact
          path="/social"
          component={(item) => (
            <MainLayout
              title={t("caseNames.social")}
              component={AppealsCitizen}
            />
          )}
        />
        <Route
          exact
          path="/new-appeals"
          component={(item) => (
            <MainLayout
              title={t("caseNames.newAppeals")}
              component={NewAppeals}
            />
          )}
        />
        <Route
          exact
          path="/appeals-monitoring"
          component={(item) => (
            <MainLayout
              title={t("caseNames.appealsMonitoring")}
              component={AppealsMonitoring}
            />
          )}
        />
        <Route
          exact
          path="/category-analytics"
          component={(item) => (
            <MainLayout
              title={t("caseNames.categoryAnalytics")}
              component={CategoryAnalytics}
            />
          )}
        />
        <Route
          exact
          path="/air-pollution"
          component={(item) => (
            <MainLayout
              title={t("caseNames.airPollution")}
              component={Ecology}
            />
          )}
        />
        <Route
          exact
          path="/pedestrian-flow"
          component={(item) => (
            <MainLayout
              title={t("caseNames.pedestrianFlow")}
              component={VideoStreamContainer}
            />
          )}
        />
        <Route
          exact
          path="/car-flow"
          component={(item) => (
            <MainLayout
              title={t("caseNames.carFlow")}
              component={() => <VideoStreamContainer auto={true} />}
            />
          )}
        />
        <Route
          exact
          path="/analytical-indicators"
          component={(item) => (
            <MainLayout
              title={t("caseNames.analyticalIndicators")}
              component={Statistics}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/crime"
          component={(item) => (
            <MainLayout
              title={t("caseNames.criminalSituation")}
              component={CrimeData}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <Route
          exact
          path="/"
          component={(item) => (
            <MainLayout
              title={t("caseNames.analyticalIndicators")}
              component={Statistics}
            />
          )}
        />
        <PrivateRoute
          exact
          path="/damu-med"
          component={(item) => (
            <MainLayout
              title={t("caseNames.morbidityData")}
              component={Damumed}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/analytical-indicators"
          component={(item) => (
            <MainLayout
              title="Аналитические индикаторы"
              component={AnalyticalIndicators}
            />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/income"
          component={(item) => (
            <MainLayout title={t("caseNames.income")} component={Income} />
          )}
          checkRole={this.checkRoute()}
        />
        <PrivateRoute
          exact
          path="/outcome"
          component={(item) => (
            <MainLayout title={t("caseNames.costs")} component={Outcome} />
          )}
          checkRole={this.checkRoute()}
        />
        <Route
          exact
          path="/monitoring-state"
          component={(item) => (
            <MainLayout
              title={t("caseNames.monitoringState")}
              component={MonitoringState}
            />
          )}
        />
        <Route
          exact
          path="/iQala"
          component={(item) => (
            <MainLayout title={t("IQala")} component={IQala} />
          )}
        />
        <Route
          exact
          path="/weather-forecast"
          component={(item) => (
            <MainLayout
              title={t("caseNames.weatherForecast")}
              component={WeatherForecast}
            />
          )}
        />
        <Route
          exact
          path="/meteorological-monitoring"
          component={(item) => (
            <MainLayout
              title={t("caseNames.meteorologicalMonitoring")}
              component={MeteorologicalMonitoring}
            />
          )}
        />
        <Route
          exact
          path="/hydrological-monitoring"
          component={(item) => (
            <MainLayout
              title={t("caseNames.hydrologicalMonitoring")}
              component={HydrologicalMonitoring}
            />
          )}
        />
        <Route
          exact
          path="/buisnesMap"
          component={(item) => (
            <MainLayout
              title={t("caseNames.buisnesMap")}
              component={BuisnesMap}
            />
          )}
        />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  }
}

export default withTranslation()(
  withRouter(
    connect(
      (state) => ({
        redirectTo: state.redirect.redirectTo,
      }),
      (dispatch) => ({
        onRedirect: () => dispatch(redirect()),
      })
    )(App)
  )
);
